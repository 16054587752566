
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Recu from '../../components/recu-pdf/Recu';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { calculateTotalFrais, fetchFacture } from '../../data/slices/factureSlice';
import { Stack, Typography } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import { COLORS, toFrench } from '../../settings/themeSettings';



const RecuViewer = () => {
    const { id, clientId } = useParams();
    const [facture, setFacture] = useState({});
    const [priceToFrench, setPriceToFrench] = useState(null);
    const [paiements, setPaiements] = useState(0);

    const navigateTo = useNavigate();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        fetchFacture(id).then((response) => response.json())
            .then(async (facture) => {
                // console.log(resp);
                setLoading(false);
                setFacture(facture);
            })
            .catch(err => {
                setLoading(false);

                console.log(err);
            })
            ;
    }, []);

    useEffect(() =>{

        if(facture && facture.paiements && facture.paiements.length > 0){

            setPaiements(calculateTotalFrais(facture.paiements))

        }
       

    }, 
    [facture]);


    useEffect(() =>{

        if(paiements){
            setPriceToFrench(toFrench(Number(paiements) ))
        }

    }, [paiements]);



    return <div>
        <Stack
            direction="row"
            justifyContent="flex-start"
            gap="5"
            alignItems="flex-start"

        >
            <div onClick={() => navigateTo(`/billeterie/factures/${clientId}/details/${id}`)}
                style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                <WestIcon style={{ fill: 'white', fontSize: 28 }} />
            </div>
            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, ml: 5, width: '100%' }}>Reçu PDF </Typography>

        </Stack>

        {
          (  loading  ||( facture && facture.paiements && facture.paiements.length > 0 && priceToFrench == null))?
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
                :

                <PDFViewer style={{ width: '100%', height: '100vh' }} >

                    <Recu facture={facture} priceToFrench={priceToFrench} />

                </PDFViewer>
        }

      
    </div>

}



export default RecuViewer;