import { View, Text } from "@react-pdf/renderer";
import { COLORS, priceFormatter } from "../../settings/themeSettings";





const DashboardTablePDFII = ({ caption, data, title }) => {



    return <View style={{ width: '100%' }} wrap={false}>
        <Text style={{
            fontSize: 12,
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: COLORS.secondary,
            //padding: 5,
            height : 15,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginBottom: '10px',
        }}>
            {caption}
        </Text>
        {title && <View

            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: 5,
                paddingTop: 2,
                paddingHorizontal: 2
            }}
        >
            <Text
                style={{ width: '100%' }}
            > {""}</Text>
            {title.map((tl) => {
                return <View key={tl} style={{flexDirection: 'column', width: '100%'}}> 
                    <Text
                    style={{ width: '100%', fontSize: 8, textAlign: 'center' }} 
                >{tl}</Text> 
                <View style={{ flexDirection: 'row' , marginTop: 5}}> 
                <Text
                    style={{ width: '100%', fontSize: 8, textAlign: 'center' }} 
                >Montant</Text> 
  <Text style={{ width: '100%', fontSize: 8, textAlign: 'center' }} 
                >Pourcentage</Text> 
                </View>
                
                </View> ;
            })}
        </View>}
        <View style={{ padding: 5, display: 'flex', flexDirection: 'row', gap: 2 }}>



            <View style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>

                {Object.keys(data).map((key) => <View key={key} style={{ flexDirection: 'column' }} >

                    <Text style={{ width: '100%', fontSize: 8, backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5 }}

                    >{key}</Text>

                </View>)}
            </View>

            {
                title && title.map((tl, key) => <View key={key + 1} style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>


                    {Object.keys(data).map((attr) => <View key={attr} style={{ flexDirection: 'column', width: '100%' }} >
                        {(() => {

                            // if (/marge/ig.test(tl)) {
                            //     return <Text style={{ width: '100%', fontSize: 8, backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5, textAlign: /marge/ig.test(tl) ? 'right' : 'left' }}

                            //     > {priceFormatter(data[attr][tl])}</Text>
                            // } else {
                                if (Array.isArray(data[attr][tl])) {
                                    return <View style={{display: 'flex', flexDirection: 'row', gap: 2}}>
                                        {data[attr][tl].map((val) => <Text key={val} style={{ width: '100%', fontSize: 8, backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5, textAlign: /FCFA/ig.test(val) ? 'right' : 'left' }}

                                        > {val} </Text>)}
                                    </View>
                                }
                                return <Text style={{ width: '100%', fontSize: 8, backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5, textAlign: /FCFA/ig.test(data[attr][tl]) ? 'right' : 'left' }}

                                > {data[attr][tl]} </Text>

                          //  }

                        })()}



                    </View>)}
                </View>)
            }









            {/* 

                // data.map((item, idx) => <View key={idx} style={{
                //      flex: 1,
                //      display: 'flex',
                //      flexDirection: 'column',
                //      gap: 5
                //       }}>
                //     <Text
                //         style={{ 
                //             width: '100%', fontSize: 8, backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5 }}

                //     >{item.title}</Text>
                //     {Array.isArray(item.value) ? item.value.map((v, key) => <Text
                //         style={{ width: '100%', fontSize: 8 , backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5}}

                //         key={key} >{v}</Text>) : <Text style={{ width: '100%', fontSize: 8 , backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5}}
                //         >{item.value}</Text>}
                // </View>

                // ) */}

        </View>

    </View>;
}



export default DashboardTablePDFII;