
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../assets/logo-aft4.png";
import { COLORS, calculateDays, dateFormatter, priceFormatter, priceFormatterV2, toEuro } from '../../settings/themeSettings';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingVertical: 30
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    logoText: {
        fontSize: 12,
        color: 'black',
        paddingLeft: '35px'
    },
    title: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    titleRight: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        // height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2
    },
    titleSpan: {
        textTransform: 'uppercase',
        fontSize: 10,
        marginTop: 2
    },
    newtitleStyle: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
        alignSelf: 'flex-start',
        marginVertical: 2,
        paddingHorizontal: 5,
        paddingVertical: 2,
        backgroundColor: COLORS.gray,
        borderRadius: 2



    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginVertical: 2,
        backgroundColor: COLORS.gray,
        paddingHorizontal: 5,
        paddingVertical: 2
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginHorizontal: 2,
        paddingHorizontal: 5,
        borderBottom: '0.5px solid black',
        marginVertical: 2
    }

});


const DossierPDFV2 = ({ dossier }) => {



    const calculateFrais = (frais) => {

        return frais.reduce((sum, current) => {


            return {
                montant: sum.montant + (current.quantite * current.prixUnitaire)
            }

        }, {
            montant: 0
        }).montant;

    }

    return <Document>

        <Page size="A4" style={styles.page}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
                minHeight: 50,
                padding: 5
            }}>
                <Image
                    style={{
                        height: 45,
                        width: 100
                    }}
                    source={logo} />
            </View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%',
                    minHeight: 80,
                    paddingHorizontal: '25px',
                    marginTop: 20
                }}

            >

                <View

                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        flex: .4,
                        paddingTop: 3
                        // alignSelf: 'flex-end'


                    }}


                >
                    <View style={{
                        height: 15,
                        width: '100%',
                        backgroundColor: COLORS.gray,
                        marginBottom: 5,
                        borderRadius: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    > <Text style={{
                        fontSize: 12,
                        color: 'black',
                        fontWeight: 'bold'
                    }}>{(dossier.type + '').toUpperCase()}</Text></View>
                    <View style={styles.title}><Text style={{ ...styles.titleSpan }}> Reference :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dossier.reference}  </Text></View>
                    <View style={styles.title}><Text style={styles.titleSpan}> date :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{dossier.date?.split('T')[0].replace(/\-/g, "/")}</Text></View>
                    {/* <View style={styles.title}><Text style={styles.titleSpan}> Agence :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dossier.numeroFacture}  </Text></View> */}
                    <View style={styles.title}><Text style={styles.titleSpan}> TO/Client :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{dossier.toClient?.libelle} </Text></View>




                </View>

                <View
                    style={{
                        //width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        flex: .4
                    }}

                >
                    <View style={styles.titleRight}><Text style={styles.titleSpan}> Programme :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dossier.programme?.libelle} </Text></View>
                    <View style={styles.titleRight}><Text style={styles.titleSpan}> Pax :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dossier.pax} </Text></View>
                    <Text style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        textAlign: 'left',
                        color: 'black',
                        alignSelf: 'flex-start',
                        marginVertical: 2

                    }}> Services</Text>
                    <View style={styles.titleRight}><Text style={styles.titleSpan}> Véhicule :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dossier.vehicule?.modele} </Text></View>

                    <View style={styles.titleRight}><Text style={styles.titleSpan}> Guide :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dossier.guide?.nomComplet} </Text></View>
                    <View style={styles.titleRight}><Text style={styles.titleSpan}> Chauffeur :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{dossier.chauffeur?.nomComplet} </Text></View>






                </View>




            </View>


            {dossier.dateVolArrivee && <>

                <View style={{
                    height: 1,
                    width: '60%',
                    backgroundColor: 'black',
                    marginTop: '20px',
                    marginLeft: 'auto',
                    marginRight: 'auto'

                }}></View>

                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '15px',
                    gap: '15px'
                }}>

                    <View style={{
                        width: '45%'
                    }}>


                        <Text style={styles.newtitleStyle}> Information de vol</Text>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Date Vol Arrivée :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dateFormatter(dossier.dateVolArrivee)} </Text></View>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Numéro vol arrivée :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{dossier.numeroVolArrivee} </Text></View>

                    </View>

                    <View style={{
                        width: '45%'
                    }}>

                        <Text style={styles.newtitleStyle}></Text>

                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Date Vol Départ :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dateFormatter(dossier.dateVolArrivee)} </Text></View>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Numéro vol Départ :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{dossier.numeroVolDepart} </Text></View>

                    </View>







                </View>

            </>
            }

            {/* <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%',
                    minHeight: 80,
                    paddingHorizontal: '25px',
                    marginTop: 20
                }}

            >

                <View
                    style={{
                        //width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        flex: .4
                    }}

                >

                    <Text style={styles.newtitleStyle}> Rooming</Text>
                    {
                        dossier.rooming?.map((person) => <View style={styles.titleRight}> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {`${person.civilite} ${person.prenom} ${person.nom}`} </Text></View>

                        )
                    }





                </View>

                <View
                    style={{
                        //width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        flex: .4
                    }}

                >

                    <Text style={styles.newtitleStyle}> DETAILS</Text>
                    <View style={styles.titleRight}><Text style={styles.titleSpan}> Locations :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dossier.locations?.length} </Text></View>
                    <View style={styles.titleRight}><Text style={styles.titleSpan}> Transfert :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {dossier.transferts?.length} </Text></View>
                    <View style={styles.titleRight}><Text style={styles.titleSpan}> Excursion :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{dossier.excursions?.length} </Text></View>






                </View>




            </View> */}





            <>

                <View style={{
                    paddingHorizontal: '25px',
                    marginTop: 5
                }}>
                    <Text style={styles.newtitleStyle}>Hebergements</Text>
                    <View style={styles.tableHeader}>

                        <Text style={{ width: '20%', fontSize: 10 }}>Date</Text>
                        <Text style={{ width: '10%', fontSize: 10 }}>Nuitée</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Prestataire</Text>
                        <Text style={{ width: '10%', fontSize: 10 }}>PAX</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Régime</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Montant (FCFA)</Text>

                    </View>


                    {
                        dossier.hotels?.map((h) => <View style={styles.tableRow}>

                            <Text style={{ width: '20%', fontSize: 10 }}> {dateFormatter(h.dateDebut)} </Text>
                            <Text style={{ width: '10%', fontSize: 10 }}> {calculateDays(h.dateDebut, h.dateFin)} </Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{h.hotel.nom}</Text>
                            <Text style={{ width: '10%', fontSize: 10 }}>{h.pax} </Text>
                            <Text style={{ width: '20%', fontSize: 10 }}> {h.regime}</Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{h.montant} </Text>

                        </View>)
                    }


                    <View style={styles.tableRow}>

                        <Text style={{ width: '20%', fontSize: 12, fontWeight: 'bold' }}> Total </Text>

                        <Text style={{ width: '20%', fontSize: 10 }}>

                            {
                                dossier.frais?.find((f) => f.frais == "HEBERGEMENT").montant || 0
                            }

                        </Text>

                    </View>


                </View>


                <View style={{
                    paddingHorizontal: '25px',
                    marginTop: 5
                }}>
                    <Text style={styles.newtitleStyle}>Excursions</Text>
                    <View style={styles.tableHeader}>

                        <Text style={{ width: '20%', fontSize: 10 }}>Date</Text>
                        <Text style={{ width: '10%', fontSize: 10 }}>Heure</Text>
                        <Text style={{ width: '30%', fontSize: 10 }}>Excursion</Text>
                        <Text style={{ width: '10%', fontSize: 10 }}>PAX</Text>
                        <Text style={{ width: '30%', fontSize: 10 }}>Montant (FCFA)</Text>

                    </View>


                    {
                        dossier.excursions?.map((e) => <View style={styles.tableRow}>

                            <Text style={{ width: '20%', fontSize: 10 }}> {dateFormatter(e.date)} </Text>
                            <Text style={{ width: '10%', fontSize: 10 }}> {e.heure} </Text>
                            <Text style={{ width: '30%', fontSize: 10 }}>{e.type.libelle}</Text>
                            <Text style={{ width: '10%', fontSize: 10 }}>{e.pax} </Text>
                            <Text style={{ width: '30%', fontSize: 10 }}>{e.montant} </Text>

                        </View>)
                    }

                    <View style={styles.tableRow}>

                        <Text style={{ width: '20%', fontSize: 12, fontWeight: 'bold' }}> Total </Text>

                        <Text style={{ width: '20%', fontSize: 10 }}>

                            {
                                dossier.frais?.find((f) => f.frais == "EXCURSION").montant || 0
                            }

                        </Text>

                    </View>

                </View>

                <View style={{
                    paddingHorizontal: '25px',
                    marginTop: 5
                }}>
                    <Text style={styles.newtitleStyle}>Transferts</Text>
                    <View style={styles.tableHeader}>

                        <Text style={{ width: '20%', fontSize: 10 }}>Date</Text>
                        <Text style={{ width: '10%', fontSize: 10 }}>Heure</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Départ</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Arrivée</Text>
                        <Text style={{ width: '10%', fontSize: 10 }}>PAX</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Montant (FCFA)</Text>

                    </View>

                    {
                        dossier.transferts?.map((t) => <View style={styles.tableRow}>

                            <Text style={{ width: '20%', fontSize: 10 }}> {dateFormatter(t.date)} </Text>
                            <Text style={{ width: '10%', fontSize: 10 }}> {t.heure} </Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{t.lieuDepart}</Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{t.lieuArrivee} </Text>
                            <Text style={{ width: '10%', fontSize: 10 }}>{t.pax} </Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{t.montant} </Text>

                        </View>)
                    }

                    <View style={styles.tableRow}>

                        <Text style={{ width: '20%', fontSize: 12, fontWeight: 'bold' }}> Total </Text>

                        <Text style={{ width: '20%', fontSize: 10 }}>

                            {
                                dossier.frais?.find((f) => f.frais == "TRANSFERT").montant || 0
                            }

                        </Text>

                    </View>

                </View>

                <View style={{
                    paddingHorizontal: '25px',
                    marginTop: 5
                }}>
                    <Text style={styles.newtitleStyle}>Locations</Text>
                    <View style={styles.tableHeader}>

                        <Text style={{ width: '30%', fontSize: 10 }}>Date Début</Text>
                        <Text style={{ width: '30%', fontSize: 10 }}>Date Fin</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Nombre de Jour</Text>

                        <Text style={{ width: '20%', fontSize: 10 }}>Montant (FCFA)</Text>

                    </View>


                    {
                        dossier.locations?.map((l) => <View style={styles.tableRow}>

                            <Text style={{ width: '30%', fontSize: 10 }}> {dateFormatter(l.dateDebut)} </Text>
                            <Text style={{ width: '30%', fontSize: 10 }}> {dateFormatter(l.dateFin)} </Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{l.nombreJour}</Text>

                            <Text style={{ width: '20%', fontSize: 10 }}>{l.montant} </Text>

                        </View>)
                    }

                    <View style={styles.tableRow}>

                        <Text style={{ width: '20%', fontSize: 12, fontWeight: 'bold' }}> Total </Text>

                        <Text style={{ width: '20%', fontSize: 10 }}>

                            {
                                dossier.frais?.find((f) => f.frais == "LOCATION").montant || 0
                            }

                        </Text>

                    </View>

                </View>

                <View style={{
                    paddingHorizontal: '25px'
                }}>
                    <Text style={styles.newtitleStyle}>Factures</Text>
                    <View style={styles.tableHeader}>

                        <Text style={{ width: '20%', fontSize: 10 }}>Date</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Numero</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Etat</Text>
                        <Text style={{ width: '10%', fontSize: 10 }}>Type</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Montant (FCFA)</Text>

                    </View>


                    {
                        dossier.factureCircuits?.map((f) => <View style={styles.tableRow}>

                            <Text style={{ width: '20%', fontSize: 10 }}> {dateFormatter(f.date)} </Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{f.numero}</Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{f.etat} </Text>
                            <Text style={{ width: '10%', fontSize: 10 }}> {f.typeFacture}</Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{f.frais.reduce((sum, current) => {
                                return { montant: sum.montant + (current.quantite * current.prixUnitaire) };
                            }, { montant: 0 }).montant
                            } </Text>

                        </View>)
                    }



                </View>
            </>

            <>



                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '15px',
                    paddingHorizontal: '25px'
                }}>

                    {/* <View style={{
                        width: '50%',
                        marginTop: '20px'
                    }}>



                        {
                           dossier.frais?.map((f) => <View style={styles.titleRight}><Text style={styles.titleSpan}> {f.frais} :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {f.montant + ' FCFA'} </Text></View>)
                        }

                    </View> */}

                    <View style={{
                        width: '60%',
                        marginTop: '20px'
                    }}>



                        <Text style={styles.newtitleStyle}> DOCUMENTS COMPTABLES</Text>
                        <View style={{...styles.titleRight, marginBottom: 1}}><Text style={styles.titleSpan}> Mode Réglement :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{dossier.methodePaiement} </Text></View>
              
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Enveloppe:</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {
                            dossier.envelops?.reduce((sum, current) => {
                                return {
                                    montant: sum.montant + current.montant
                                }
                            }, { montant: 0 }).montant + ' FCFA'
                        } </Text></View>

                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Montant en CFA :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {
                            dossier.frais?.reduce((sum, current) => {
                                return {
                                    montant: sum.montant + current.montant
                                }
                            }, { montant: 0 }).montant + ' FCFA'
                        } </Text></View>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Montant en EURO :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {
                            toEuro(
                                (dossier.frais?.reduce((sum, current) => {
                                    return {
                                        montant: sum.montant + current.montant
                                    }
                                }, { montant: 0 }).montant)
                            )
                        } </Text></View>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Benefice :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>

                            {
                                (() => {
                                    let benefice = (dossier.factureCircuits?.reduce((sum, current) => {

                                        if (current.type == 'AV' || current.type == 'FP') {
                                            return sum;
                                        }
                                        return {
                                            montant: sum.montant + calculateFrais(current.frais || [])
                                        }
                                    }, {
                                        montant: 0
                                    }).montant - dossier.frais?.reduce((sum, current) => {
                                        return {
                                            montant: sum.montant + current.montant
                                        }
                                    }, { montant: 0 }).montant);

                                    return (benefice > 0 ? (benefice + 'FCFA' + ' / ' + Math.round(benefice / 650) + '€') : 0);


                                })()


                            }

                        </Text></View>

                    </View>







                </View>

            </>







        </Page>

    </Document>

}

export default DossierPDFV2;