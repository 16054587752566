import React, { useEffect, useState }  from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../assets/logo-aft4.png";
import { COLORS, numberToLetterFrench, priceFormatterV2, toFrench } from '../../settings/themeSettings';
import iata from "../../assets/iata-logo.png";
import { calculateTotalFrais } from '../../data/slices/factureSlice';
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    logoText: {
        fontSize: 8,
        color: COLORS.secondary,
        paddingLeft: '25px'
    },
    title: {
        fontSize: 14,
        color: COLORS.secondary,
        fontWeight: 'bold',
        width: '100%',
        height: 20,
        textAlign: 'center',
        marginTop: 10
    }
});




const Recu = ({ facture, priceToFrench }) => {



    return <Document>
        <Page size="A4" wrap={true} style={styles.page}>


            {[1, 2].map((item, idx) => <View>

                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    minHeight: 80,
                    padding: 5,
                    alignItems: 'flex-start'
                }}>
                    <View style={{

                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end'

                    }}>
                        <Image source={logo} style={{height: '50px', width: '200px', marginTop: '20px'}} />
                        <Text style={{ ...styles.logoText, marginTop: '10px' }}>
                            Saly Portudal, Route de Ngaparou
                        </Text>
                        <Text style={styles.logoText}>A côté de  Pharmacie Salve Régina</Text>
                        <Text style={styles.logoText}>Mbour Sénégal</Text>

                    </View>

                    <View style={{

                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        paddingRight: '35px'

                    }}>

                        <Image source={iata} style={{
                            height: '50px',
                            width: '60px',
                            alignSelf: 'flex-end'
                        }} />


                        <Text style={{ ...styles.logoText, fontSize: 8, textAlign: 'right' }}>
                            {`N° Agrément 72200166\n` + `Tél: +221 33 957 81 81\nEmail: resa@africa4tourism.com\nwww.africa4tourism.com`}
                        </Text>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginTop: '10px'
                        }}>
                            <Text style={{
                                fontSize: 13,
                                color: COLORS.secondary

                            }}>N° DE DOSSIER </Text>
                            <Text style={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                color: COLORS.primary
                            }}> N° {facture.numeroDossier}</Text>

                        </View>

                    </View>
                </View>
                <View style={{
                    marginTop: 5,
                    marginBottom: 5
                }}>
                    <Text style={styles.title}>{`reçu de paiement`.toUpperCase()} </Text>
                </View>

                <View style={{

                    paddingHorizontal: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                }}>
                    <Text style={{ textAlign: 'right', width: '100%', fontSize: 8, color: 'black', marginBottom: '20px' }}>B.P.F. CFA:  {facture.paiements && priceFormatterV2(calculateTotalFrais(facture.paiements))} F CFA </Text>

                    <Text style={{ textAlign: 'left', width: '100%', fontSize: 10, color: 'black' }}>{`Reçu de :  ${(facture.client && ((facture.client?.prenom ?? '') + ' ' + facture.client.nom))}`.toUpperCase()}</Text>
                    <Text style={{ textAlign: 'left', width: '100%', fontSize: 10, color: 'black' }}>MODE DE PAIEMENT: {facture.paiements && facture.paiements.reduce((t, n) => ({ methode: `${t.methode}, ${n.methode}` }), { methode: '' }).methode.replace(',', '')}  </Text>
                    <Text style={{ textAlign: 'left', width: '100%', fontSize: 10, color: 'black' }}>LA SOMME DE F CFA:  {facture.paiements && priceFormatterV2(calculateTotalFrais(facture.paiements))} F CFA </Text>
                    <Text style={{ textAlign: 'left', width: '100%', fontSize: 10, color: 'black' }}>MOTIF : Vente de billet </Text>


                    {facture.paiements &&
                        <Text
                            style={{
                                fontSize: 10,
                                color: 'black',
                                textAlign: 'left'
                            }}
                        >
                            Arrêté à la somme de {priceToFrench}
                        </Text>}

                    <View style={{
                        height: '60px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'

                    }}>
                        <Text style={{
                            fontSize: 12,
                            color: COLORS.secondary,
                            textDecoration: 'underline'
                        }}>Le caissier</Text>

                        <Text
                            style={{
                                fontSize: 12,
                                color: 'black'
                            }}
                        >Saly, le {(new Date()).toISOString().split('T')[0].replace(/\-/g, "/")}</Text>
                    </View>

                    <Text
                        style={{
                            fontSize: 10,
                            color: 'black',
                            textAlign: 'center'
                        }}
                    >
                        AFRICA FOR TOURISM SARL au capital de 5.000.000 F CFA - RC: SN.THS.2014.M.2451 - NINEA: 002442424
                    </Text>

                </View>
                <View style={{
                    height: '30px',
                    
                    borderBottom: idx == 0 ? '1px solid black' : 'none'
                }} />


            </View>)

            }
        </Page>
    </Document>
}


export default Recu;